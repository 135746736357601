import React, { useEffect, useState } from 'react';
import styles from './home.module.scss';
import axios from 'axios';
import QRCode from 'react-qr-code';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const Home: React.FC = () => {
    const [amount, setAmount] = useState(0);
    const [qrcode, setQrCode] = useState(false);
    const [paymentRes, setPaymentRes] = useState<any>({});
    const [timeLeft, setTimeLeft] = useState<number | null>(null);
    const apiBaseUrl = process.env.REACT_APP_BASE_URL;
    
    let randnum = Math.random().toString().slice(2,11);

    let body = {
        orderAmount: amount * 100,
        ordeCurrency: "INR",
        clientTransactionId:randnum.toString(),
    }

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(parseInt(event.target.value));
    };

    const handlePayment = async () => {
        try {
            if (amount) {
                const res = await axios.post(`${apiBaseUrl}/pg/order/create`, body,{
                    headers:{
                        clientId:2
                    }
                });

                setPaymentRes(res?.data?.data);
                console.log("ffffffffffff",res?.data?.data)
                if (res.status === 200) {
                    window.open(
                        res?.data?.data?.pgUrl,
                        "_blank"
                      );
                    // toast.success("Payment successful");
                    // setQrCode(true);
                    // setTimeLeft(300);
                    setAmount(0)
                }
            } else {
                toast.error("Please Enter the Amount");
            }
        } catch (error) {
            console.log(error, "error");
            toast.error("Payment failed");
        }
    };

    useEffect(() => {
        let countdown: NodeJS.Timeout | null = null;

        if (timeLeft !== null && timeLeft > 0) {
            countdown = setTimeout(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
        } else if (timeLeft === 0) {
            setQrCode(false);
            setTimeLeft(null);
            toast.info("QR code expired");
        }

        return () => {
            if (countdown) {
                clearTimeout(countdown);
            }
        };
    }, [timeLeft]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };


    const handleScan = (data: any) => {
        if (data) {
            console.log('Result: ', data);
        }
    }
    console.log("dsssssssssssss",paymentRes)
    return (
        <div className={styles.payment_container}>
            <div className={styles.payment_card}>
                <h2>Make a Payment</h2>
                <input
                    type="number"
                    placeholder="Enter Amount"
                    value={amount}
                    onChange={handleAmountChange}
                    className={styles.amount_input}
                />
                <button onClick={handlePayment} className={styles.pay_button}>
                    Pay Now
                </button>
            </div>

            <div>
                {qrcode && (
                    <>
                        {timeLeft !== null && (
                            <p className={styles.timer}>Time left: {formatTime(timeLeft)}</p>
                        )}
                        <QRCode value={paymentRes?.pgUrl}
                            style={{ width: '100%' }}
                        />
                    </>
                )}
            </div>
            <ToastContainer />
        </div>
    );
};

export default Home;
