import './App.css';
import Home from './component/home/Home';

function App() {
  return (
    <>
     <Home/>
    </>
  );
}

export default App;
